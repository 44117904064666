<template>
	<div class="rooms">
		<h2 class="rooms__title">{{ $t('ads.mapRoom') }}</h2>

		<div class="rooms__controls">
			<ul class="rooms__tags rooms-tags">
				<li v-for="(tag, index) in tagList" :key="index">
					<button
						type="button"
						class="btn rooms-tags__btn"
						@click="changeRoom(tag, index)"
						:class="{
							'rooms-tags__btn--active':
								tag === activeBtn || (index === 0 && !activeBtn)
						}"
					>
						{{ tag }}
					</button>
				</li>
			</ul>
		</div>
		<or-slider
			v-slot="{ item }"
			:collectionData="roomList"
			:sliderProperties="{ pagination: true }"
			@cardClick="openImage"
		>
			<RoomCard :content="item" size="small" class="rooms-carousel__card" />
		</or-slider>
	</div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
	components: {
		RoomCard: defineAsyncComponent(() => import('@/components/cards/Room'))
	},
	props: {
		plan: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			currentSlide: 0,
			keyCarousel: 1,
			layoutCarousel: null,
			activeBtn: ''
		}
	},
	computed: {
		tagList() {
			let arrTag = this.plan?.map((a) => ({ ...a }))
			if (this.plan) {
				arrTag = arrTag
					.sort((a, b) => a.plans.bedrooms_count - b.plans.bedrooms_count)
					.map((el) => {
						if (el.plans.bedrooms_count === 0) return 'Studios'
						else return el.plans.bedrooms_count
					})
				arrTag.unshift(this.$t('general.all'))
				return arrTag
			} else {
				return [this.$t('general.all'), 'Studios', 1, 2, 3, 4, '5+']
			}
		},
		roomList() {
			let result
			if (this.activeBtn === 'Studios')
				result = this.plan.filter((el) => el.plans.bedrooms_count == 0)
			else if (this.activeBtn === '5+')
				result = this.plan.filter((el) => el.plans.bedrooms_count > 4)
			else if (this.activeBtn)
				result = this.plan.filter(
					(el) => el.plans.bedrooms_count === this.activeBtn
				)
			return result || this.plan
		}
	},
	methods: {
		changeRoom(number, index) {
			if (index === 0) this.activeBtn = ''
			else this.activeBtn = number
		},
		openImage(e, payload, index) {
			const imgList = []
			this.roomList.map((el) => {
				const blueprint = el.plans?.plan[0]?.blueprint
				//добавил проверку на наличие картинок
				if (blueprint && blueprint.length > 0) {
					imgList.push(blueprint[0])
				}
			})
			this.$store.commit('showModal', {
				name: 'PlaceCarousel',
				props: {
					items: imgList,
					index
				}
			})
		}
	}
}
</script>

<style lang="scss">
.rooms {
	position: relative;
	&__title {
		margin: 0;
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 700;
	}

	&__controls {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		margin-bottom: 30px;
		position: relative;
	}

	.rooms-tags {
		display: flex;
		gap: 10px;

		@media screen and (max-width: 768px) {
			gap: 5px;
		}

		&__btn {
			padding: 10px;
			font-size: 16px;
			line-height: 24px;
			border-radius: 4px;
			border: 1px solid rgba(0, 0, 0, 0.1);

			&--active {
				color: #c99d56;
				border-color: #c99d56;
			}
		}
	}
	.boots-slider__pagination {
		position: absolute;
		top: 50px;
		right: 0;
	}
}
</style>
