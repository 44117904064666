<template>
	<div class="kinds-housing">
		<h2
			@click="toggle"
			:class="{ active: isShow }"
			class="kinds-housing__title"
		>
			{{ title || $t('ads.tagTitle2') }}
			<or-icon type="arrow" />
		</h2>
		<transition name="slide-fade">
			<ul v-if="isShow" class="kinds-housing__list">
				<li v-for="(tag, index) in content" :key="index">
					<button type="button" @click="search(tag)" class="btn">
						{{ tag.title }}
					</button>
				</li>
			</ul>
		</transition>
	</div>
</template>

<script>
export default {
	props: {
		title: {
			type: String,
			required: true
		},
		content: {
			type: Array,
			default: () => []
		},
		project: {
			type: Object,
			default: () => ({
				title: '',
				slug: '',
				region: {}
			})
		},
		type: {
			type: String,
			default: 'project' // project/complex
		}
	},
	data() {
		return {
			isShow: false
		}
	},
	computed: {
		isProject() {
			return this.type === 'project'
		}
	},
	methods: {
		search(payload) {
			if (this.isProject) {
				const region = {
					id: this.project.region.id,
					title: this.project.region.title,
					price_from: this.project.region.price_from,
					slug: this.project.region.slug
				}

				this.$router.push({
					name: 'Search',
					params: { lang: this.$store.state.uiLanguage },
					query: {
						type_id: payload.id,
						pr_slug: this.project.slug,
						project: this.project.title,
						region: JSON.stringify([region])
					}
				})
			}
		},
		toggle() {
			this.isShow = !this.isShow
		}
	}
}
</script>

<style lang="scss">
.kinds-housing {
	&__title {
		margin: 0;
		margin-bottom: 20px;
		font-size: 18px;
		font-weight: 700;
		display: flex;
		align-items: center;
		gap: 20px;
		cursor: pointer;
		i {
			transition: all 0.2s ease;
			margin-top: 2px;
		}
		&.active {
			i {
				transform: rotate(-180deg);
			}
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		gap: 10px;
	}

	button {
		font-size: 16px;
		color: #808080;
		line-height: 24px;
		padding: 10px;
		border-radius: 4px;
		border: 1px solid rgba(0, 0, 0, 0.1);
	}
}
</style>
